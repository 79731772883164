import React from "react";
import MUIButton from "@mui/material/Button";

interface ButtonProps {
  classesNames?: string;
  onClick?: () => void;
  value?: React.ReactNode;
  disable?: boolean;
  style?: React.CSSProperties;
  endIcon?: React.ReactNode;
  fullWidth?: boolean;
  size?: "small" | "medium" | "large";
  variant?: "outlined" | "contained" | "text";
  sx?: {};
}

const Button: React.FC<ButtonProps> = ({
  classesNames,
  onClick,
  value,
  disable,
  style,
  endIcon,
  fullWidth,
  size,
  variant,
  sx,
}) => {
  return (
    <MUIButton
      sx={sx}
      type="button"
      className={classesNames}
      disabled={disable}
      variant={variant}
      onClick={onClick}
      style={style}
      endIcon={endIcon}
      size={size}
      fullWidth={fullWidth}
    >
      {value}
    </MUIButton>
  );
};

export default Button;
